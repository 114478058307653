// --------------------------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------------------------

// Global
// ------------------------------------------------------------------------
$border-radius-extra: 6px !default;
$box-shadow-base: 0 0 3px rgba(black, .2) !default;
$box-shadow-base-hover: 0 0 7px rgba(black, .3) !default;
$box-shadow-focus-base: 0 0 5px rgba(black, .2) !default;

// Paths
// ------------------------------------------------------------------------
$img-path: "../images/" !default;
$font-path: "../fonts/" !default;

// Fonts
// ------------------------------------------------------------------------
$root-font-size: 16 !default; // PX
$body-font-size: $font-size-base !default; // PX
$font0: "Ubuntu", tahoma, sans-serif !default;
$font1: "Bebas Neue", arial, sans-serif !default;
$font2: "MontserratAlternates-Bold", arial, sans-serif !default;
$font3: "MontserratAlternates-Regular", arial, sans-serif !default;

// Colors
// ------------------------------------------------------------------------
$color0: #2b75aa !default;
$color0-alt: #4295d0 !default;
$color1: #f78705 !default;
$color1-alt: #d67404 !default;
$color2: #289e7b !default;
$color2-alt: #38cda0 !default;
$color2-alt2: #33ba91 !default;
$color3: #373838 !default;
$color4: #494c53 !default;
$color5: #eaeef3 !default;
$color6: #f9f9f9 !default;
$color7: #e6e6e6 !default;
$color8: #705EA6 !default;



// Number of colors (without their alternate value)
$nb-colors: 7;

$header-bg: white !default;
$center-bg: #f3f3f3 !default;
$footer-bg: $color4 !default;

// Main nav
// ------------------------------------------------------------------------
$mainnav-bg: $color5 !default;

// Buttons
// ------------------------------------------------------------------------
$btn-font-size: 18 !default;

// Forms
// ------------------------------------------------------------------------
$input-box-shadow: $box-shadow-base !default;
$input-box-shadow-focus: $box-shadow-focus-base !default;

// Tabs
// ------------------------------------------------------------------------
$tabs-bg: $color4 !default;
$tabs-height: 44px !default;
$tabs-gutter-width: 0 !default;
$tabs-color: white !default;
$tabs-btn-bg: $color2 !default;
$tabs-border-width: 5px !default;
$tabs-active-border-color: $color2 !default;
$tabs-separator-color: white !default;

// Template Homepage
// ------------------------------------------------------------------------
$home-search-height: 770px !default; // min-height

// Template Residence
// ------------------------------------------------------------------------
$residence-search-height: 200px !default; // min-height
$carousel-bg: darken($color6, 2%) !default;
$carousel-thumb-spacing: 10px !default;
$spin-control-height: 28px;
$booking-bar-bg: $color2 !default;
$height-map: 800px;

// Template Checkout
// ------------------------------------------------------------------------
$checkout-steps: 3 !default;
$checkout-step-bg: $color4 !default;
$checkout-step-color: white !default;
$checkout-step-padding: .5em .8em !default;
$checkout-step-bg-active: $color2 !default;
$checkout-step-color-active: white !default;
$checkout-step-width: 15% !default;
$checkout-step-width-active: 70% !default;
$checkout-step-num-width: 14% !default;
$checkout-step-name-width: 86% !default;

// Icons
// ------------------------------------------------------------------------
$icon-mid-width: 28px !default;
$icon-mid-height: $icon-mid-width !default;

$icon-list:
  "lang",
  "user",
  "users",
  "heart",
  "list",
  "date",
  "map",
  "favorite",
  "bath",
  "simplebed",
  "doublebed",
  "laundromat",
  "accessibility",
  "proximity",
  "payment",
  "world",
  "screen",
  "like",
  "food",
  "target";
