// --------------------------------------------------------------------------------------------
// COMMONS
// --------------------------------------------------------------------------------------------

html {
	font-size: 100%; // Mobile
	@include breakpoint(tablet) { font-size: 100%; }
	@include breakpoint(desktop) { font-size: 100%; }
}

body {
	@include font-size($body-font-size, em);
	background: $body-bg;
	font-family: $font-family-sans-serif;
	position: relative;
}

a {
	color: $color0; text-decoration: none;
	@include hover{ text-decoration: none; }
}

button {
	background: none;
	border: 0;
}

p {
	margin: 0; padding: 0 0 1em; line-height: 1.1em;
	&:last-child { padding-bottom: 0; }
}

ul, ol {
	margin: 0; padding: 0;
	li { list-style: none; }
}

.infographie-etapes {
	max-width: 100%;
}