// --------------------------------------------------------------------------------------------
// GENERIC PLACEHOLDERS
// --------------------------------------------------------------------------------------------

// Text color
// -----------------------------------------------------------------------
%color0 {
	color: $color0;
}
%color1 {
	color: $color1;
}
%color1-alt {
	color: $color1-alt;
}
%color2 {
	color: $color2;
}
%color3 {
	color: $color3;
}
%color4 {
	color: $color4;
}

// Border color
// -----------------------------------------------------------------------
%bordercolor0 {
	border-color: $color0;
}
%bordercolor1 {
	border-color: $color1;
}
%bordercolor1-alt {
	border-color: $color1-alt;
}
%bordercolor2 {
	border-color: $color2;
}
%bordercolor3 {
	border-color: $color3;
}
%bordercolor4 {
	border-color: $color4;
}

// Background-color
// -----------------------------------------------------------------------
%bgcolor0 {
	background-color: $color0;
	@include hover {
		background-color: darken($color0, 5%);
	}
}
%bgcolor1 {
	background-color: $color1;
	@include hover {
		background-color: darken($color1, 5%);
	}
}
%bgcolor1-alt {
	background-color: $color1-alt;
	@include hover {
		background-color: darken($color1-alt, 5%);
	}
}
%bgcolor2 {
	background-color: $color2;
	@include hover {
		background-color: darken($color2, 5%);
	}
}
%bgcolor3 {
	background-color: $color3;
	@include hover {
		background-color: darken($color3, 5%);
	}
}
%bgcolor4 {
	background-color: $color4;
	@include hover {
		background-color: darken($color4, 5%);
	}
}
// Residence
// -----------------------------------------------------------------------
%header_residence {
	.name {
		@include font-size(25);
		padding-bottom: .3em;
		&, a {
			color: $color1-alt;
		}
	}

	.address {
		@include font-size(14);
		font-weight: bold;
		color: $color3;
		padding: 0;
		line-height: 1rem;
	}

	.add-to-favs {
		@include icon('favorite');
		float: right;
		width: $icon-mid-width;
		height: $icon-mid-height;
		> span {
			@extend .sr-only;
		}
	}

}

%price {
	@include font-size(40);
	font-family: $font1;
	color: $color0;
	text-align: right;
	line-height: 1em;
	padding: 0 0 .4rem;
}

%price-info {
	@include font-size(14);
	text-align: right;
	color: $color3;
	line-height: 1.2em;
}

%aide {
	border-radius: 0px;
	background: $color2;
	color: #fff;
	width: 1.1em;
	height: 1.1em;
	display: inline-block;
	position: relative;
	top: 2px;
	text-align: center;
	span {
		font-style: italic;
		line-height: 1em;
		display: inline-block;
		position: relative;
		top: -2px;
		left: -1px;
	}
	@media print {
		width: auto;
		height: auto;
		background: transparent;
		span {
			display: none;
		}
		&:after {
			color: $color2;
			content: " (" attr(data-tooltip) ")";
		}
	}
}

// -----------------------------------------------------------------------
%legend_label {
	color: $color0;
	@include font-size(17);
	font-weight: bold;
	margin: 0 0 0.5em;
	border: 0;
	display: block;
	line-height: 1em;
}
%label_checkbox {
	@include font-size(14);
	display: block;
	overflow: auto;
	color: $gray-dark;
	font-weight: bold;
	line-height: 1.4rem;
	margin-left: 1.4em;
	padding-left: 0;
}