// Core variables and mixins
@import "variables";

@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
  }
}

// Reset and dependencies
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
