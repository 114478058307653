// ----------------------------------------------------------------------------
// MISC
// ----------------------------------------------------------------------------

@mixin group {
	*zoom: 1;
	&:before, &:after {
		content: ' '; display: table;
	}
	&:after { clear: both; }
}

@mixin img-responsive ($max-width: 100%, $margin: 0 auto) {
	display: block;
	margin: $margin;
	max-width: $max-width;
}

@mixin vertical-align {
	&:before {
		display: inline-block;
		content: '';
		height: 100%;
		vertical-align: middle;
	}
}

@mixin truncate-text ( $overflow: ellipsis ) {
	overflow: hidden; white-space: nowrap;
	text-overflow: $overflow; // values are: clip, ellipsis, or a string
}

@mixin font-size ( $size, $unit: rem ) {
	font-size: $size + px; // Fallback PX IE8
	font-size: ($size / $root-font-size) + $unit;
}

@mixin hover {
	&:hover, &:focus { @content; }
}

// ----------------------------------------------------------------------------
// BREAKPOINTS
// ----------------------------------------------------------------------------

@mixin breakpoint ( $point ) {

	@if $point == desktop {
		@media (min-width: ($screen-lg + 1)) { @content; }
	}
	@else if $point == tablet {
		@media (min-width: ($screen-sm + 1)) { @content; }
	}
	@else if $point == tabletonly {
		@media (min-width: $screen-sm) and (max-width: $screen-md) { @content; }
	}
	@else if $point == mobileonly {
		@media (max-width: $screen-sm)  { @content; }
	}
	@else if $point == desktoponly {
		@media (min-width: $screen-lg)  { @content; }
	}
	@else if $point == mobileandtabletonly {
		@media (max-width: $screen-md-min)  { @content; }
	}
	@else if $point == mobile-portrait {
		@media (max-width: ($screen-sm - 1)) and (orientation: portrait)  { @content; }
	}
	@else if $point == mobile-landscape {
		@media (max-width: ($screen-sm - 1)) and (orientation: landscape)  { @content; }
	}
	@else if $point == tablet-portrait {
		@media (min-width: $screen-sm) and (orientation: portrait)  { @content; }
	}
	@else if $point == tablet-landscape {
		@media (min-width: $screen-sm) and (orientation: landscape)  { @content; }
	}

}

@mixin tight-row($in-row: false) {

	@if $in-row == true {

		@include breakpoint(mobileonly) {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

	} @else {

		@include breakpoint(mobileonly) {
			padding-left: ($grid-gutter-width / 2);
			padding-right: ($grid-gutter-width / 2);
		}

	}

}

// ----------------------------------------------------------------------------
// CSS3
// ----------------------------------------------------------------------------

@mixin transition($properties: all, $duration: .3s, $easing: ease-in-out) {
	transition: $properties $duration $easing;
}

@mixin linearGradient($top, $bottom){
	background: $bottom;
	background: -moz-linear-gradient(top,  $top 0%, $bottom 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom));
	background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -o-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -ms-linear-gradient(top,  $top 0%,$bottom 100%);
	background: linear-gradient(to bottom,  $top 0%,$bottom 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
}

// ----------------------------------------------------------------------------
// FONT FACE
// ----------------------------------------------------------------------------

@mixin font-face($style-name, $file, $category:"") {

	$filepath: $font-path + $file;

	@font-face {
		font-family: "#{$style-name}";
		src: url($filepath + ".eot");
		src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
	}

	%#{$style-name} {
		font: {
			@if $category != "" {
				family: "#{$style-name}", "#{$category}";
			}
			@else {
				family: "#{$style-name}";
				weight: normal;
			}
		}
	}

}

// ----------------------------------------------------------------------------
// ICONS
// ----------------------------------------------------------------------------

@mixin icon ( $name, $bg-color: transparent, $size: mid, $type: 1, $bg-position: 0 center, $bg-image: false ) {

	@if $bg-image == true {

		background-image: url(#{$img-path}icons/icon-#{$size}-#{$type}-#{$name}.png);

	} @else {

		background: url(#{$img-path}icons/icon-#{$size}-#{$type}-#{$name}.png) no-repeat $bg-position $bg-color;

	}

}