
@import "vendor/bootstrap";

@import "partials/variables"; 	// Custom variables
@import "partials/mixins"; 		// Custom mixins
@import "partials/placeholders";	// Custom generic placeholders
@import "partials/commons";	// Commons styles

* {
	background: none !important;
	box-shadow: none !important;
}

.sf-toolbar,
.tabs,
.cross-nav,
.block-menu-lg,
.block-search,
.illustration,
footer .block-menu {
	display: none !important;
}

pre {
	white-space: pre-wrap !important;
}
pre,
blockquote {
	page-break-inside: avoid;
}
tr,
img {
	page-break-inside: avoid;
}

body.residence .block-rooms .room,
body.search-city .block-rooms .room,
body.residence-carte .block-rooms .room,
body.residence .block-main-details > .inner,
body.search-city .block-main-details > .inner,
body.residence-carte .block-main-details > .inner {
	border: 1px solid #ddd;
	overflow: visible !important;
}
.block-main {
	padding: 0;
}

.container {
	width: 100%;
}

.arguments .item > .inner a {
	padding: 15px;
}

.homepage {
	.block-infos .container {
		.infos-title,
		.infos-details {
			color: $color8;
		}
	}
	.block-cities .cities-list .item .picture .name {
		color: $color2;
	}
	.block-cities .cities-list .item .picture img {
		max-height: 310px;
	}
}


.block-header {
	.hidden-xs {
		display: inline-block !important;
	}
	.block-logo {
		width: 100%;
	}
}

/* */
#CarteController {
	.leaflet-top,
	.leaflet-control {
		display: none;
	}
}

/* carte de la résidence pour l'impression */
.carte-residence-print {
	position: relative !important;
	left: 0 !important;
	top: 0 !important;
	page-break-inside: avoid;
	page-break-after: always;
	width: 100%;
	height: auto;
}

/* lors de l'impression, on affiche toutes les images en grand */
.carousel {
	.slides {
		width: 100% !important;
	}
	.slide {
		display: block;
		width: 100% !important;
		float: none;
		margin: 0 0 1em 0;
	}
	.pagination,
	.controls {
		display: none;
	}
}


/* remplacement du background des boutons par une couleur de police et une bordure*/
.btn {

	@for $i from 0 through $nb-colors {

		&.type-#{$i} {
			@extend  %color#{$i} !optional;
			border: 1px solid;
			@extend  %bordercolor#{$i} !optional;
		}

		&.type-#{$i}-alt {
			@extend  %color#{$i}-alt !optional;
			border: 1px solid;
			@extend  %bordercolor#{$i}-alt !optional;
		}

	}

	// Exception
	&.type-1 {
		color: $color1-alt;
		border: 1px solid;
		border-color: $color1-alt;
	}

}
.block-details.summary,
.block-details.payment {
	float: none !important;
	width: auto !important;
	page-break-after: always;
	page-break-inside: auto;
}

.block-rooms  {
	page-break-inside: auto;
}
.collapse {
	display: block !important;
}

.spin-control {
	.button {
		&.less,
		&.more {
			display: none;
		}
	}
	.value {
		width: 100%;
	}
}
